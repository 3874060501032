import { Priority, registerApp } from '@mfl/framework';
import { navigationItems } from '@mfe/core-navigation';
import { currentUser } from '@mfl/platform-shell';
import {
  SURVEYS_EDIT_PERMISSION,
  SURVEYS_MVP_ENTITLEMENT,
} from '@msl/surveys-gateway-sdk';
import translations from './surveys.strings';

const route = '/v1/surveys';

export async function register() {
  if (
    currentUser.isAllowedTo(SURVEYS_EDIT_PERMISSION) &&
    currentUser.isEntitledTo(SURVEYS_MVP_ENTITLEMENT)
  ) {
    await registerApp('survey-management', route);

    navigationItems.register(
      {
        key: 'SURVEYS',
        text: translations.header,
        route: route,
        fontAwesomeIcon: 'ballot-check',
      },
      { priority: Priority.Medium - 109 }
    );
  }
}
